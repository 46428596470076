<template>
    <div id="read-article" class="py-2 px-md-5 px-3">
        <Loader :state="loader_state"></Loader>
        <g-content-header :category="category_name" :sub-category="article_data.subcategory_details ? article_data.subcategory_details.name : ''"></g-content-header>
        <div class="row">
            <div class="col-md-8 text-left">
                <p class="pl_title my-1">{{article_data.title}}</p>
                <p class="pl_speaker my-1"><span class="speaker-label">By:</span> {{article_data.author_details ? article_data.author_details.name : ''}}</p>
                <p class="pl_date_label">Date: <span class=" px-1 pl_date_value">{{article_data.date}}</span></p>
            </div>
            <div class="col-md-4 text-md-right text-left my-md-0 my-4">
                <ShareNetwork
                v-for="network in networks"
                :network="network.network"
                :key="network.key"
                :title="article_data.title ? article_data.title : ''"
                :description="article_data.description"
                :url="shareURL+$route.path">
                <font-awesome-icon class="text-white h3 mx-3 sharing" :icon="['fab', network.icon]"/>
            </ShareNetwork>
            </div>
        </div>
        <div class="row mb-5 mt-5">
            <div class="col-md-12">
                <div class="pl_body" v-html="content">
                </div>
            </div>
        </div>
    </div>
</template>


<script>
    import { mapActions, mapGetters } from 'vuex'
    import gContentHeader from './general-content-header'
    import Loader from '../shared/alhaq_loader'
    import config from "../../assets/config";
    export default  {
        components: {
            "g-content-header": gContentHeader,
            "Loader" : Loader
        },
        data() {
            return {
                networks: [
                    { network: 'twitter', name: 'Twitter', icon: 'twitter', color: '#1da1f2' },
                    { network: 'whatsapp', name: 'Whatsapp', icon: 'whatsapp', color: '#25d366' },
                    { network: 'skype', name: 'Skype', icon: 'skype', color: '#00aff0' },
                ],
                loader_state : false,
                baseURL: config.baseURL,
                shareURL: config.shareURL,
                article_data: {},
                category_name: '',
                content: ''
            }
        },
        methods: {
            ...mapActions([
                'getArticleById',
                'getCategoryById'
            ])
        },
        watch: {
            'this.$route.params.art_id': {
                handler: function () {
                    this.loader_state = true
                    this.getArticleById({'id': this.$route.params.art_id, 'contentDetails': true}).then(response => {
                        this.article_data = response.data.data
                        this.content = JSON.parse(this.article_data.body)
                    }).then(()=> {
                        this.getCategoryById({'id': this.article_data.subcategory_details.category_id}).then(response => {
                            this.category_name = response.data.data.name;
                            this.loader_state = false
                        })
                    })
                },
                immediate: true
            }
        }

    }
</script>

<style scoped>
    .pl_title {
        font-family: brandFont-bold;
        font-size: 26px;
        color: #28abe1;
        word-break: break-word;
    }
    .pl_speaker, .pl_desc {
        font-size: 18px !important;
        color: #ffffff;
        font-family: brandFont-subcat;
    }
    .pl_speaker {
        color: #28abe1 !important;
    }
    .pl_date_label, .speaker-label {
        font-family: brandFont-subcat;
        font-size: 16px !important;
        color: white;
    }
    .pl_date_value {
        font-family: brandFont-subcat;
        font-size: 16px !important;
        color: #28abe1;
    }
    .pl_body {
        color: white;
        font-size: 14px;
        word-break: break-all;
    }
    .pl_body >>> .ql-video {
        width: 100% !important;
        min-height: 400px !important;
    }
    .pl_body >>> img {
        width: 100%;
        min-height: auto;
    }
    #pl_player {
        width: 100%;
        max-height: 600px;
        height: 600px;
    }
    .card_title {
        color: #28abe1;
        font-family: brandFont-bold;
        font-size: 18px;
        position: relative;
        z-index: 2;
    }
    .data-card {
        cursor: pointer;
        border: none;
        overflow: hidden;
    }
    .data-card {
        border-bottom: 1px dashed white !important;
    }
    .data-card:last-child {
        border-bottom: none !important;
    }
    .data-card-img-wrapper {
        overflow: hidden;
    }
    .data-card-img {
        transition: transform 1s ease-in-out;
    }
    .data-card-img:hover {
        transform: scale(1.5);
    }
    .sharing {
        cursor: pointer;
    }
    .sharing:hover {
        color: #28abe1 !important;
    }

</style>