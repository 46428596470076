<template>
    <div id="read-article-view">
        <r-art></r-art>
    </div>
</template>

<script>
    import readArticle from '../components/public/read-article'
    export default  {
        components: {
            'r-art': readArticle
        }
    }


</script>


<style scoped>


</style>